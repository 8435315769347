<template>
  <el-dialog :title="title" v-model="dialogShow" :show-close="true" @close="handleClose" @open="handleOpen" :width="width">
    <list-condition-template v-loading="showLoading" ref="table" :element-loading-text="loadingText"
                             :rows="rows" :total="total" :columns="columns" :multiple-selected="multipleSelected"
                             :index-column="indexColumn" :row-class-name="rowClassName" :page-size="pageSize" v-model:listQuery="listQuery"
                             @page-info-change="handlePageInfoChange" :table-height="tableHeight" @selected="handleSelected">
      <template #condition>
        <slot name="condition"></slot>
      </template>
      <template #columns>
        <slot></slot>
      </template>
    </list-condition-template>
    <template #footer class="dialog-footer">
      <el-button size="mini" type="primary" @click="closeDialog" v-waves>确定</el-button>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/list-layout.scss";
</style>

<script>
import ListConditionTemplate from '@/components/templates/listConditionTemplate'
import waves from '@/directive/waves' // 水波纹指令

export default {
  emits:['close','open','page-info-change','click', 'selected'],
  directives: {
    waves
  },
  components: {
    ListConditionTemplate
  },
  /**
   * 输入参数
   */
  props: {
    /**
     * 标题
     */
    title: {
      type: String,
      default: '数据筛选'
    },
    /**
     * 列
     */
    columns: {
      type: Array,
      default: function () {
        return []
      }
    },
    /**
     * 弹出框宽度
     */
    width: {
      type: String,
      default: '50%'
    },
    /**
     * 是否多选
     */
    multipleSelected: {
      type: Boolean,
      default: false
    },
    /**
     * 是否包含索引列
     */
    indexColumn: {
      type: Boolean,
      default: false
    },
    /**
     * 选中数据后是否关闭对话框
     */
    closeOnSelected: {
      type: Boolean,
      default: true
    },
    /**
     * 对话框显示状态
     */
    show: {
      type: Boolean,
      default: false
    },
    /**
     * 数据行
     */
    rows: {
      type: Array
    },
    /**
     * 数据总数
     */
    total: {
      type: Number,
      default: 0
    },
    /**
     * 是否显示加载条
     */
    showLoading: {
      type: Boolean,
      default: false
    },
    /**
     * 数据加载显示文本
     */
    loadingText: {
      type: String,
      default: '数据加载中'
    },
    /**
     * 默认分页配置
     */
    pageSize: {
      type: Object,
      default: function () {
        return {
          default: 10,
          size: [10, 20, 30, 50]
        }
      }
    },
    /**
     * 行样式
     */
    rowClassName: {
      type: Function,
      default: null
    },
    /**
     * 表格高度
     */
    tableHeight: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      listQuery:{
        condition:{},
        pageInfo:{
          pageIndex:1,
          pageSize:20,
          orders:[]
        }
      },
      dialogShow: false,
      selectedRows: [],
      closing: false
    }
  },
  computed: {},
  watch: {
    /**
     * 监控外部显示变量变化
     * 传递到dialog组件
     */
    show: function (newShow, oldShow) {
      this.dialogShow = newShow
    },
    /**
     * 监控内部显示属性变化
     * 传递到外部调用变量
     */
    dialogShow: function (newDialogShow, oldDialogShow) {
      this.$emit('update:show', newDialogShow)
    }
  },
  methods: {
    /**
     * 行选中事件处理
     * @param {*} rows 行数据
     */
    handleSelected (rows) {
      if(rows){
        if (!this.multipleSelected) {
          this.onSelected(rows)
        } else {
          this.selectedRows = rows
        }
      }
      
    },
    /**
     * 处理排序信息变化事件
     * @param {*} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      if (this.closing) {
        return
      }
      this.onPageInfoChage(pageInfo)
    },
    /**
     * 外部条件变化时处理
     */
    handleConditionChange () {
      this.$refs.table.changeCondition()
    },
    /**
     * 处理关闭事件
     */
    handleClose () {
      this.closing = true
      this.$refs.table.resetTable()
      this.onClose()
    },
    /**
     * 处理打开事件
     */
    handleOpen () {
      this.closing = false
      this.onOpen()
      this.onPageInfoChage(this.listQuery.pageInfo)
      // if (this.$refs.table) {
      //   this.$refs.table.changeCondition()
      // }
    },
    /**
     * 触发分页信息变化事件
     * @param {*} pageInfo 分页信息
     */
    onPageInfoChage (pageInfo) {
      this.$emit('page-info-change', pageInfo)
    },
    /**
     * 触发数据选中事件
     * @param {*} rows 行数据
     */
    onSelected (rows) {
      this.$emit('selected', rows)
      if (!this.multipleSelected && this.closeOnSelected) {
        this.closeDialog()
      }
    },
    /**
     * 触发打开事件
     */
    onOpen () {
      this.$emit('open')
    },
    /**
     * 触发关闭事件
     */
    onClose () {
      this.$emit('close')
    },
    /**
     * 主动关闭对话框
     */
    closeDialog () {
      if (this.multipleSelected) {
        this.onSelected(this.selectedRows)
      }
      this.$emit('close')
    },
    /**
     * 重置条件并触发条件变化事件
     */
    changeCondition () {
      this.onPageInfoChage(this.listQuery.pageInfo)
    }
  },
  mounted () {}
}
</script>
