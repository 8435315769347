import api from './api'
/**
 * 公共接口
 */
export class CommonApi extends api {


  verifyRoleName(name, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyRoleName${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        name
      }
    })
  }

  verifyPhone(phone, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyPhone${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        phone
      }
    })
  }

  verifyEmail(email, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyEmail${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        email
      }
    })
  }

  verifyDeptName(name, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyDeptName${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        name
      }
    })
  }

  verifyMaterialCategoryName(name, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyMaterialCategoryName${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        name
      }
    })
  }

  verifyMaterialCategoryCode(code, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyMaterialCategoryCode${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        code
      }
    })
  }


  verifyModelName(name, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyModelName${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        name
      }
    })
  }

  verifyMaterialCode(code, id) {
    return this.request({
      showLoading: false,
      url: `${this.servicePath}/${this.modulePath}/verifyMaterialCode${id ? '/' + id : ''}`,
      method: 'post',
      dataType: 'form',
      data: {
        code
      }
    })
  }

  verifyManufacturerCode (code) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/verifyManufacturerCode/${code}`,
        method: 'get'
    })
  }

  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
  getCustomerPage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/customerPage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  /**
   * 获取所有部门
   * @param {*} 分页请求数据
   */
  getAllDepts() {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/dept/all`,
      method: 'get'
    })
  }

  /**
   *
   * @returns 获取物料属性
   */
  getCategories() {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/category/getAll`,
      method: "get",
    });
  }

    /**
   *
   * @returns 获取品类by 型号名称
   */
    getCategoryByModelName(modelName) {
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/model/getCategory?modelName=${modelName}`,
        method: "get",
      });
    }

  downloadTemplate () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/template`,
      method: 'get',
      responseType: 'blob'
    }).then(res => {
      saveAs(res.data, '标签申请导入模板')
    })
  }

  getModelsPaged ({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/model/page`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  getManufacturers (modelId) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/manufacturer/all?modelId=${modelId}`,
      method: 'get'
    })
  }

    getAllManufacturers () {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getManufacturers`,
            method: 'get'
        })
    }

    getAllModels (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getModels`,
            method: 'post',
            data: condition
        })
    }

  getDealers () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/dealers/all`,
      method: 'get'
    })
  }


}

export default new CommonApi('common')
