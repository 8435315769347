<template>
  <div>
    <el-tabs @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="流程审核配置" name="first">
        <el-divider content-position="left">业务部申请人员</el-divider>
          <el-row type="flex" justify="space-between">
            <el-button v-if="!businessManagerTable || businessManagerTable.length <1" type="success" size="mini" icon="el-icon-plus" v-waves @click="handleSelectManager(businessManager.key)">添加</el-button>
            <el-button v-else type="primary" size="mini" icon="el-icon-edit" @click="handleSelectManager(businessManager.key)">变更</el-button>
            <!-- <div >
               <el-button v-if="businessManagerTable" type="primary" size="mini" v-waves @click="submitManager(businessManager.key)">保存</el-button>
            </div> -->
          </el-row>
          <el-table :data="businessManagerTable" border style="margin-top: 15px; width: 100%">
            <el-table-column min-width="150px" label="姓名" property="name" align="center">
              <template #default="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" label="手机号" property="phone" align="center">
              <template #default="scope">
                <span>{{scope.row.phone}}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" label="部门" property="dept" align="center">
              <template #default="scope">
                <span>{{scope.row.dept && scope.row.dept.name}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100">
            <template #default="scope">
              <i class="el-icon-delete" style="cursor:pointer;" alt="移除" @click="deleteManager(scope.row, businessManager.key)"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-divider content-position="left">品质部审核人员</el-divider>
        <el-row type="flex" justify="space-between">
          <el-button v-if="!qualityManagerTable || qualityManagerTable.length <1" type="success" size="mini" icon="el-icon-plus" v-waves @click="handleSelectManager(qualityManager.key)">添加</el-button>
          <el-button v-else type="primary" size="mini" icon="el-icon-edit" @click="handleSelectManager(qualityManager.key)">变更</el-button>
          <!-- <div >
            <el-button v-if="qualityManagerTable" type="primary" size="mini" v-waves @click="submitManager(qualityManager.key)">保存</el-button>
          </div> -->
        </el-row>
        <el-table :data="qualityManagerTable" border style="margin-top: 15px; width: 100%">
          <el-table-column min-width="150px" label="姓名" property="name" align="center">
            <template #default="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="手机号" property="phone" align="center">
            <template #default="scope">
              <span>{{scope.row.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="部门" property="dept" align="center">
            <template #default="scope">
              <span>{{scope.row.dept && scope.row.dept.name}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="100">
            <template #default="scope">
              <i class="el-icon-delete" style="cursor:pointer;" alt="移除" @click="deleteManager(scope.row, qualityManager.key)"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-divider content-position="left">采购部审核及申请人员</el-divider>
        <el-row type="flex" justify="space-between">
          <el-button v-if="!purchaseManagerTable || purchaseManagerTable.length <1" type="success" size="mini" icon="el-icon-plus" v-waves @click="handleSelectManager(purchaseManager.key)">添加</el-button>
          <el-button v-else type="primary" size="mini" icon="el-icon-edit" @click="handleSelectManager(purchaseManager.key)">变更</el-button>
          <!-- <div >
            <el-button v-if="purchaseManagerTable" type="primary" size="mini" v-waves @click="submitManager(purchaseManager.key)">保存</el-button>
          </div> -->
        </el-row>
        <el-table :data="purchaseManagerTable" border style="margin-top: 15px; width: 100%">
          <el-table-column min-width="150px" label="姓名" property="name" align="center">
            <template #default="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="手机号" property="phone" align="center">
            <template #default="scope">
              <span>{{scope.row.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="部门" property="dept" align="center">
            <template #default="scope">
              <span>{{scope.row.dept && scope.row.dept.name}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作" width="350px" class-name="fixed-width">
            <template  #default="scope">
              <el-button type="danger" size="mini" @click="deleteManager(scope.row, purchaseManager.key)" v-waves>删除</el-button>
            </template>
          </el-table-column> -->
          <el-table-column align="center" width="100">
            <template #default="scope">
              <i class="el-icon-delete" style="cursor:pointer;" alt="移除" @click="deleteManager(scope.row, purchaseManager.key)"></i>
            </template>
          </el-table-column>
        </el-table>
        <filter-dialog ref="customerTable" title="选择成员" v-model="selecteCustomers.show" :rows="selecteCustomers.rows" :total="selecteCustomers.total" :index-column="true"
          :multiple-selected="true" width="80%" @page-info-change="handleCustomerPageInfoChange" :show-loading="selecteCustomers.showLoading"
          @close="handleCustomersDialogClose" @selected="handleCustomerSelected">
            <template #condition>
              <el-form-item>
                <el-input @keyup.enter="handleCustomersFilter" placeholder="员工名称" v-model="selecteCustomers.condition.keyword" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleCustomersFilter">搜索</el-button>
              </el-form-item>
            </template>
            <template #default>
              <el-table-column min-width="150px" label="名称" align="center" sortable="custom" property="name">
                <template #default="scope">
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="150px" label="部门" align="left" header-align="center">
                <template #default="scope">
                  <span>{{scope.row.dept.name}}</span>
                </template>
              </el-table-column>
            </template>
        </filter-dialog>
      </el-tab-pane>
      <el-tab-pane label="标签相关配置" name="second">
        <el-divider content-position="left">标签比例配置</el-divider>
        <div>
          <el-row>
            <el-input
              v-model="startInput"
              size="small"
              placeholder="标签起始数量（包含）"
              style="width:250px;"
              disabled
              maxlength="7"
            ></el-input>
            <span style="margin-left: 15px;margin-top: 5px;margin-bottom: 20px;"> — </span>
            <el-input 
              v-model="endInput"
              size="small"
              placeholder="标签上限数量（包含）"
              style="width:250px; margin-left: 20px;"
              clearable
              maxlength="7"
            />
            <el-input
              v-model="appendInput"
              size="small"
              placeholder="追加标签数量"
              style="width:250px; margin-left: 20px;"
              clearable
              maxlength="7"
            />
            <div>
              <el-button style="margin-left: 10px;" type="text" size="small" v-waves icon="el-icon-plus" @click="handleAppendLabelRatio">追加规则</el-button>
            </div>
          </el-row>
          <span style="font-size: 12px;color: red;">* 目前系统标签上限数量（包含）为：1000000 </span>
          <div>
            <el-button style="margin-bottom: 20px;margin-top: 10px" type="primary" size="mini" v-waves @click="handleSaveLabelRatio">保存</el-button>
          </div>
          <el-table :data="labelRatios" style="width: 100%" border id="labelRatioTsble">
          <el-table-column min-width="150px" label="标签起始数量（包含）" property="startNum" align="center">
              <template #default="scope">
                <span>{{scope.row.startNum}}</span>
              </template>
          </el-table-column>
          <el-table-column label="标签上限数量（包含）" property="endNum" align="center">
            <template #default="scope">
              <span>{{scope.row.endNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="追加标签数量" property="addCount" align="center">
            <template #default="scope">
              <span>{{scope.row.addCount}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300px" class-name="fixed-width">
              <template  #default="scope">
                <el-button type="primary" size="mini" @click="handleEdit(scope.row, scope.$index)" v-waves>修改</el-button>
                <el-button type="danger"  size="mini" @click="handleDelete(scope.row, scope.$index)" v-waves>删除</el-button>
              </template>
            </el-table-column>
        </el-table>
        </div>
        <el-divider content-position="left">标签单价配置</el-divider>
        <el-form ref="price" size="mini" :model="priceInfo">
          <el-row><span style="font-size: 12px;color: red;">* 标签单价范围0到100元</span></el-row>
          <el-form-item style="margin-top:10px;" label="厨电标签（元/套）：" prop="kitchenAppliancePrice" :rules="[{required: true, message: '厨电标签价格不能为空',trigger:'blur'}]">
            <el-input-number v-model="priceInfo.kitchenAppliancePrice" size="small" :disabled="kitchenApplianceDisabel" style="width:220px;" placeholder="请输入厨电标签价格" :step="0.01" :precision="2" :min="0" :max="100" clearable ></el-input-number>
            <el-button style="margin-left: 10px;" v-if="kitchenApplianceDisabel" type="primary" size="small" v-waves  @click="editPrice('kitchenAppliancePrice')">修改</el-button>
            <el-button style="margin-left: 10px;" v-else type="primary" size="small" v-waves  @click="submitPrice">确认</el-button>
          </el-form-item>

          <el-form-item label="小电标签（元/套）：" prop="homeAppliancePrice" :rules="[{required: true, message: '小电标签价格不能为空',trigger:'blur'}]">
            <el-input-number v-model="priceInfo.homeAppliancePrice" size="small" :disabled="homeApplianceDisabel" style="width:220px;" placeholder="请输入厨电标签价格" :step="0.01" :precision="2" :min="0" :max="100" clearable ></el-input-number>
            <el-button style="margin-left: 10px;" v-if="homeApplianceDisabel" type="primary" size="small" v-waves  @click="editPrice('homeAppliancePrice')">修改</el-button>
            <el-button style="margin-left: 10px;" v-else type="primary" size="small" v-waves  @click="submitPrice">确认</el-button>
          </el-form-item>
        </el-form>

        

        <el-dialog v-model="editShow" title="标签生成比例配置" center width="800px">
            <el-form ref="ratioInfo" :model="ratioInfo" label-width="100px">
              <el-row>
                <el-form-column width="100px" label="标签起始数量（包含）" property="startNum">
                  <el-input v-model="ratioInfo.startNum" maxlength="7" disabled></el-input>
                </el-form-column>
                <span style="margin-left: 15px;margin-top: 10px;margin-bottom: 20px;margin-right: 15px;"> — </span>
                <el-form-column width="100px" label="标签上限数量（包含）" property="endNum">
                  <el-input v-model="ratioInfo.endNum" maxlength="7"></el-input>
                </el-form-column>
                <el-form-column style="margin-left: 15px;" width="50px" label="追加标签数量" property="addCount">
                  <el-input v-model="ratioInfo.addCount" maxlength="4"></el-input>
                </el-form-column>
              </el-row>
              <el-form-item>
                <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
                <el-button size="mini" type="primary" @click="onSubmitButtonClick" :loading="ratioInfo.loading">{{ratioInfo.loading ? '提交中' : '确定'}}</el-button>
              </el-form-item>
            </el-form>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="系统时间配置" name="third">
        <el-divider content-position="left">标签签收时间配置</el-divider>
        <el-form ref="price" size="mini" :model="systemTimeInfo" label-position="top">
          <el-form-item label="系统自动签收时间" prop="automaticSigningTime" :rules="[{required: true, message: '系统自动签收时间不能为空',trigger:'blur'}]">
            <el-row><span><font color="#a9a9a9">标签从系统分发出去后，如果超过此设置时间还没有被ODM制造商签收，系统将会自动完成签收。系统默认为 15 天，用户可配置时间不得小于 7 天。</font></span></el-row>
            <el-row style="margin-top:20px;">
              <el-input v-if="automaticSigningTimeDisabel" style="width:150px;" v-model="automaticSigningTimeDesc"></el-input>
              <el-input-number v-model="systemTimeInfo.automaticSigningTime" size="small" v-else style="width:220px;" placeholder="请输入系统自动签收时间" :step="1" :min="7" :max="30" clearable ></el-input-number>
              <el-button style="margin-left: 10px;" v-if="automaticSigningTimeDisabel" type="primary" size="small" v-waves  @click="editSystemTime('automaticSigningTime')">修改</el-button>
              <el-button style="margin-left: 10px;" v-else type="primary" size="small" v-waves  @click="submitSystemTime">确认</el-button>
            </el-row>
          </el-form-item>

          <el-form-item label="月度生产报表填写通知时间" prop="reportFillingNoticeTime" :rules="[{required: true, message: '月度生产报表填写通知时间不能为空',trigger:'blur'}]">
            <el-row><span><font color="#a9a9a9">系统每月向ODM制造商推送填写“月度生产报表”的消息的时间。</font></span></el-row>
            <el-row style="margin-top:20px;">
              <el-input v-if="reportFillingNoticeTimeDisabel"  style="width:150px;" v-model="reportFillingNoticeTimeDesc"></el-input>
              <el-input-number v-model="systemTimeInfo.reportFillingNoticeTime" size="small" v-else style="width:220px;" placeholder="请输入月度生产报表填写通知时间" :step="1"  :min="1" :max="30" clearable ></el-input-number>
              <el-button style="margin-left: 10px;" v-if="reportFillingNoticeTimeDisabel" type="primary" size="small" v-waves  @click="editSystemTime('reportFillingNoticeTime')">修改</el-button>
              <el-button style="margin-left: 10px;" v-else type="primary" size="small" v-waves  @click="submitSystemTime">确认</el-button>
            </el-row>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs >
  </div>
</template>

<script>
import configApi from "@/apis/config"
import filterDialog from '@/components/filterDialog/filterDialog'
import commonApi from '@/apis/common'
export default {
  components: {
    filterDialog
  },
  data() {
      return {
        editShow: false,
        activeName: 'first',
        ratioInfo: {
          loading: false,
          id: undefined,
          startNum: undefined,
          endNum: undefined,
          addCount: undefined,
          index: undefined
        },
        labelPriceId:undefined,
        priceInfo:{
          kitchenAppliancePrice:0.15,
          homeAppliancePrice:0.12,
        },
        kitchenApplianceDisabel:true,
        homeApplianceDisabel:true,
        systemTimeId:undefined,
        systemTimeInfo:{
          automaticSigningTime:15,
          reportFillingNoticeTime:2
        },
        automaticSigningTimeDesc:'',
        reportFillingNoticeTimeDesc:'',
        automaticSigningTimeDisabel:true,
        reportFillingNoticeTimeDisabel:true,
        startInput: 1,
        endInput: undefined,
        appendInput: undefined,
        labelRatioId: undefined,
        labelRatios: [],
        businessManagerTable:[],
        qualityManagerTable:[],
        purchaseManagerTable: [],
        businessManager:{
          id:null,
          value:null,
          key:"businessManager",
          manager:{}
        },
        qualityManager:{
          id:null,
          value:null,
          key:"qualityManager",
          manager:{}
        },
        purchaseManager:{
          id:null,
          value:null,
          key:"purchaseManager",
          manager:{}
        },
        multipleSelected:false,
        selecteCustomers:{
          show:false,
          condition: {
            keyword: undefined,
            enabled: 1
          },
          rows:[],
          total:0,
          showLoading:false
        },
        key:''
      }
  },
   watch:{
  },
  mounted(){
    configApi.getByKey("businessManager").then(res =>{
      if(res.data && res.data.id){
        this.businessManager = res.data
        if(res.data.value){
          this.businessManagerTable = JSON.parse(res.data.value)
        }
      }
    })
    configApi.getByKey("qualityManager").then(res =>{
      if(res.data && res.data.id){
        this.qualityManager = res.data
        if(res.data.value){
          this.qualityManagerTable = JSON.parse(res.data.value)
        }
      }
    })
    configApi.getByKey("purchaseManager").then(res =>{
      if(res.data && res.data.id){
        this.purchaseManager = res.data
        if(res.data.value){
          this.purchaseManagerTable = JSON.parse(res.data.value)
        }
      }
    })
    configApi.getByKey("labelRatio").then(res =>{
      if(res.data && res.data.id){
        this.labelRatioId = res.data.id
        if(res.data.value){
          this.labelRatios = JSON.parse(res.data.value)
          this.startInput = parseInt(this.labelRatios[this.labelRatios.length-1].endNum) + 1
        }
      }
    })
    configApi.getByKey("labelPrice").then(res =>{
      if(res.data && res.data.id){
        this.labelPriceId = res.data.id
        if(res.data.value){
          this.priceInfo = JSON.parse(res.data.value)
        }
      }
    })
    configApi.getByKey("systemTime").then(res =>{
      if(res.data && res.data.id){
        this.systemTimeId = res.data.id
        if(res.data.value){
          this.systemTimeInfo = JSON.parse(res.data.value)
          this.reportFillingNoticeTimeDesc='每月 ' + this.systemTimeInfo.reportFillingNoticeTime + ' 号'
          this.automaticSigningTimeDesc = this.systemTimeInfo.automaticSigningTime + ' 天'
        }
      }
    })
  },
  methods:{
    handleClick (tab, event) {},
    handleSelectManager(key){
      this.multipleSelected = false
      this.selecteCustomers.show = true
      this.key = key
      //this.getCustomerList()
    },
    handleCustomerPageInfoChange(pageInfo){
      this.getCustomerList(pageInfo)
    },
    handleCustomersDialogClose(){
      this.selecteCustomers.condition.name = undefined
      this.selecteCustomers.rows = []
      this.selecteCustomers.show = false
    },
    handleCustomerSelected(data){
      if(data && data.length>0){
        if(this.key === 'businessManager'){
          data.forEach(d => {
            if (!this.businessManagerTable.find(ar => {
              return ar.id === d.id
            })) {
              d.roles = []
              d.subscriptions = []
              this.businessManagerTable.push(d)
            }
          })
          this.businessManager.value = JSON.stringify(this.businessManagerTable)
          this.submitManager('businessManager')
        }else if(this.key === 'qualityManager'){
          data.forEach(d => {
            if (!this.qualityManagerTable.find(ar => {
              return ar.id === d.id
            })) {
              d.roles = []
              d.subscriptions = []
              this.qualityManagerTable.push(d)
            }
          })
          this.qualityManager.value = JSON.stringify(this.qualityManagerTable)
          this.submitManager('qualityManager')
        }else {
          data.forEach(d => {
            if (!this.purchaseManagerTable.find(ar => {
              return ar.id === d.id
            })) {
              d.roles = []
              d.subscriptions = []
              this.purchaseManagerTable.push(d)
            }
          })
          this.purchaseManager.value = JSON.stringify(this.purchaseManagerTable)
          this.submitManager('purchaseManager')
        }
      }
    },
    deleteManager(row, key){
      this.$confirm('确定要移除该账号吗？', '警告', {
        type: 'warning'
      }).then(() => {
        if(key === 'businessManager'){
          this.businessManagerTable = this.businessManagerTable.filter(am => {
            return !(am.id === row.id)
          })
          this.businessManager.value = JSON.stringify(this.businessManagerTable)
          this.submitManager('businessManager')
        }else if(key === 'qualityManager'){
          this.qualityManagerTable = this.qualityManagerTable.filter(am => {
            return !(am.id === row.id)
          })
          this.qualityManager.value = JSON.stringify(this.qualityManagerTable)
          this.submitManager('qualityManager')
        }else{
          this.purchaseManagerTable = this.purchaseManagerTable.filter(am => {
            return !(am.id === row.id)
          })
          this.purchaseManager.value = JSON.stringify(this.purchaseManagerTable)
          this.submitManager('purchaseManager')
        }
      })

      
    },
    handleCustomersFilter(){
      this.$refs.customerTable.changeCondition()
    },
    getCustomerList(pageInfo){
      this.selecteCustomers.showLoading = true
      commonApi.getCustomerPage({ pageInfo, condition: this.selecteCustomers.condition }).then(response => {
          this.selecteCustomers.rows = response.data.content
          this.selecteCustomers.total = response.data.totalElements
          this.selecteCustomers.showLoading = false
        }).catch(response => {
          this.selecteCustomers.showLoading = false
        })
    },
    submitManager(key){
      var data;
      if(key === 'businessManager'){
        data = this.businessManager
      }else if(key === 'qualityManager'){
        data = this.qualityManager
      }else {
        data = this.purchaseManager
      }
      configApi.insert(data).then(res => {
        this.$message.success({
          message: '操作成功',
          duration: 2000
        })
      })
    },
    handleEdit(row,index){
      this.editShow = true
      this.ratioInfo = JSON.parse(JSON.stringify(row))
      this.ratioInfo.index = index
    },
    handleDelete(row,index){
      if(this.labelRatios.length -1 === index){
        this.labelRatios = this.labelRatios.filter(l => l.startNum !== row.startNum)
        this.startInput = parseInt(row.startNum) === 0 ? 1: parseInt(row.startNum)
      }else{
        this.labelRatios = this.labelRatios.filter(l => l.startNum !== row.startNum)
        this.labelRatios[index].startNum = row.startNum === 0 ? 1: row.startNum
      }
    },
    handleAppendLabelRatio(){
      if(this.endInput && this.appendInput){
        //var value = this.startInput.replace(/^[1-9]([0-9])*$/, '');
        let ret=/^([0-9]*)$/
        if((!ret.test(this.startInput)) || (!ret.test(this.endInput))|| (!ret.test(this.appendInput))){
          this.$message.warning("请输入1~1000000的正整数")
        }else if(this.startInput < 0 || this.endInput < 0 || this.appendInput < 0){
          this.$message.warning("请输入正整数")
        }else if(this.startInput > 1000000 || this.endInput > 1000000){
          this.$message.warning("上限不超过1000000")
        }else if(this.appendInput > 100){
          this.$message.warning("追加标签上限不超过100")
        }else if(parseInt(this.startInput) >= parseInt(this.endInput)){
          this.$message.warning("上限值不能小于起始值")
        }else{
          var info ={
            startNum: this.startInput,
            endNum: this.endInput,
            addCount: this.appendInput
          }
          if(this.labelRatios && this.labelRatios.length>=10){
            this.$message.warning("规则条数不超过10条")
          }else{
            this.labelRatios.push(info)
            this.startInput = parseInt(this.endInput) + 1
            this.endInput = undefined
            this.appendInput = undefined
          }
        }
      }else{
        this.$message.warning("起始数量、上限数量、追加数量均不能为空")
      }
    },
    onCancelButtonClick(){
      this.editShow = false
    },
    onSubmitButtonClick(){
      if(this.ratioInfo.endNum && this.ratioInfo.addCount){
        let ret=/^([0-9]*)$/
        if((!ret.test(this.ratioInfo.startNum)) || (!ret.test(this.ratioInfo.endNum))|| (!ret.test(this.ratioInfo.addCount))){
          this.$message.warning("请输入1~1000000的正整数")
        }else if(this.ratioInfo.startNum < 0 || this.ratioInfo.endNum < 0 || this.ratioInfo.addCount < 0){
          this.$message.warning("请输入正整数")
        }else if(this.ratioInfo.startNum > 1000000 || this.ratioInfo.endNum > 1000000){
          this.$message.warning("上限不超过1000000")
        }else if(this.ratioInfo.addCount > 100){
          this.$message.warning("追加标签上限不超过100")
        }else if(parseInt(this.ratioInfo.startNum) >= parseInt(this.ratioInfo.endNum)){
          this.$message.warning("上限值不能小于起始值")
        }else{
          if(this.labelRatios.length-1 === this.ratioInfo.index){
            this.labelRatios[this.ratioInfo.index] = this.ratioInfo
            this.startInput = parseInt(this.ratioInfo.endNum) + 1
            this.editShow = false
          }else{
            var nextNum = parseInt(this.ratioInfo.endNum) + 1
            if(this.labelRatios[this.ratioInfo.index+1].endNum<=nextNum){
              this.$message.warning("下一阶段上限数量需大于起始数量")
            }else{
              this.labelRatios[this.ratioInfo.index] = this.ratioInfo
              this.labelRatios[this.ratioInfo.index+1].startNum = nextNum
              this.editShow = false
            }
          }
        }
      }else{
        this.$message.warning("起始数量、上限数量、追加数量均不能为空")
      }
    },
    handleSaveLabelRatio(){
      if(!this.labelRatios || this.labelRatios.length < 1){
        this.$message.warning("请先添加追加规则")
      }else{
        var value = JSON.stringify(this.labelRatios)
        var systemConfig = {
          key: 'labelRatio',
          value: value,
          id: this.labelRatioId
        }
        configApi.insert(systemConfig).then(res => {
          this.endInput = undefined
          this.appendInput = undefined
          this.$message.success({
            message: '配置成功',
            duration: 2000
          })
        })
      }
    },
    editPrice(key){
      if(key === 'kitchenAppliancePrice'){
        this.kitchenApplianceDisabel = false
      }

      if(key === 'homeAppliancePrice'){
        this.homeApplianceDisabel = false
      }
    },
    submitPrice(){
      var systemConfig = {
        key: 'labelPrice',
        value: JSON.stringify(this.priceInfo),
        id: this.labelPriceId
      }
      configApi.insert(systemConfig).then(res =>{
        this.priceInfo = JSON.parse(res.data.value)
        this.kitchenApplianceDisabel=true
        this.homeApplianceDisabel=true
        this.$message.success({
          message: '配置成功',
          duration: 2000
        })
      })
    },
    editSystemTime(key){
      if(key === 'automaticSigningTime'){
        this.automaticSigningTimeDisabel = false
      }

      if(key === 'reportFillingNoticeTime'){
        this.reportFillingNoticeTimeDisabel = false
      }
    },
    submitSystemTime(){
      var systemConfig = {
        key: 'systemTime',
        value: JSON.stringify(this.systemTimeInfo),
        id: this.systemTimeId
      }
      configApi.insert(systemConfig).then(res =>{
        this.systemTimeInfo = JSON.parse(res.data.value)
        this.automaticSigningTimeDisabel=true
        this.reportFillingNoticeTimeDisabel=true
        this.reportFillingNoticeTimeDesc='每月' + this.systemTimeInfo.reportFillingNoticeTime + '号'
        this.automaticSigningTimeDesc = this.systemTimeInfo.automaticSigningTime + '天'
        this.$message.success({
          message: '配置成功',
          duration: 2000
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>