import api from "./api";
/**
 * 管理员用户相关api
 */
export class ConfigApi extends api {

  getByKey(key){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getByKey/${key}`,
      method: "get"
    });
  }
}

export default new ConfigApi("system/config");
